import LazyLoadComponent from '../../shared/layout/LazyLoadComponent';

/**
 * appRoutes configuration for the application.
 *
 * Each route consists of a route, path, and component to render.
 *
 * - `route`: The route pattern, which may contain dynamic segments.
 * - `path`: The actual path for the route, without any dynamic segments.
 * - `element`: The React component to render for the route.
 */
const appRoutes = {
  home: {
    route: '/:brandName',
    path: '/',
    element: LazyLoadComponent('Home'),
  },

  category: {
    route: `/:brandName/category`,
    path: '/category',
    element: LazyLoadComponent('Entity'),
  },

  product: {
    route: `/:brandName/product`,
    path: '/product',
    element: LazyLoadComponent('Entity'),
  },

  certification: {
    route: `/:brandName/certification`,
    path: '/certification',
    element: LazyLoadComponent('Certification'),
  },

  issueRequest: {
    route: `/:brandName/issue-request`,
    path: '/issue-request',
    element: LazyLoadComponent('Certification'),
  },

  success: {
    route: `/:brandName/certification/success`,
    path: '/certification/success',
    element: LazyLoadComponent('Success'),
  },

  faq: {
    route: `/:brandName/faq`,
    path: '/faq',
    element: LazyLoadComponent('Faq'),
  },

  /*
  We've implemented a distinction between two 404 pages:
  
  1. `globalNotFound`: This is the default 404 page for the entire app, triggered when not in a specific brand's landing page.
  
  2. `notFound`: This 404 page is specific to a rendered brand landing page. We require this separation to handle cases where
  a resource is not found under a specific brand. It allows us to render data related to the brand on this page, hence the
  need to be under the path /:brandName/404.
*/
  globalNotFound: {
    route: '/404',
    path: '/404',
    element: LazyLoadComponent('GlobalNotFound'),
  },

  notFound: {
    route: `/:brandName/404`,
    path: '/404',
    element: LazyLoadComponent('NotFound'),
  },
};

export default appRoutes;
