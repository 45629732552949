/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */
// Lib dependencies
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

// Translations files
import translationEnDefault from '../locales/default/en/en.json';
import translationFrDefault from '../locales/default/fr/fr.json';

/* -------------------------------------------------------------------------- */
/*                                   Config                                   */
/* -------------------------------------------------------------------------- */

/**
 * Object containing the resources for different brands and languages.
 *
 * @typedef {Object} Resources
 * @property {Object} default - Default resources for en and fr languages.
 * @property {Object} noliju - Resources for the "noliju" brand.
 * @property {Object} balenciaga - Resources for the "balenciaga" brand.
 */

/**
 * @type {Resources}
 */

const resources = {
  default: {
    en: {
      translation: translationEnDefault,
    },
    fr: {
      translation: translationFrDefault,
    },
  },
};

/**
 * Configure i18n with brand-specific resources.
 *
 * @param {string} brandName - Name of the brand.
 * @param {string} [detectedLang] - Optional detected language to set as the current language.
 */
const configureI18n = (brandName, detectedLang) => {
  if (detectedLang) {
    i18n.changeLanguage(detectedLang);
  }

  const brandResources = resources[brandName];
  if (!brandResources) {
    return;
  }

  Object.entries(brandResources).forEach(([language, resource]) => {
    const { translation: localeTranslation } = resource;
    i18n.addResourceBundle(language, 'translation', localeTranslation, true, true);
  });
};

/**
 * Initialize i18n with default resources.
 */
const setupI18n = () => {
  i18n.use(initReactI18next).init({
    debug: false,
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false,
    },
    resources: resources.default,
  });
};

export { setupI18n, configureI18n };

export default i18n;
