/* -------------------------------------------------------------------------- */
/*                                   Slice                                    */
/* -------------------------------------------------------------------------- */

const brandSlice = (set) => ({
  brand: null,

  loadBrand: (data) => set({ brand: data }),
});

export default brandSlice;
