/* eslint-disable consistent-return */
/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */

// React
import { useEffect, useState } from 'react';

// Lib dependencies
import { useLocation } from 'react-router-dom';

// Components
import LoadingOverlay from '../../shared/components/organisms/LoadingOverlay';

// Configs
import { brands, allowedPaths } from '../../configs/brands.configs';
import appRoutes from '../../router/configs/routes.configs';

// Hooks
import useRouterNavigation from '../../hooks/useRouterNavigation';

// Others
import useStore from '../../shared/store';

/* -------------------------------------------------------------------------- */
/*                                  Provider                                  */
/* -------------------------------------------------------------------------- */

const BrandProvider = (props) => {
  /* ******************************** LOGIC ******************************* */

  const [isLoading, setIsLoading] = useState(true);

  const { children } = props;

  const { pathname } = useLocation();

  const { loadBrand } = useStore();

  const { navigateTo } = useRouterNavigation();

  useEffect(() => {
    const brandPath = pathname.split('/')[1].toLocaleLowerCase();

    // Exclude not allowed path names
    if (!allowedPaths.includes(brandPath)) {
      return navigateTo(appRoutes.globalNotFound.path);
    }

    // Get brand data
    const brandData = Object.values(brands).find((brand) => brand.path === brandPath);

    loadBrand(brandData);

    setIsLoading(false);
  }, []);

  /* ******************************** RENDERING ******************************* */

  if (isLoading) {
    return <LoadingOverlay visible />;
  }

  return children;
};

export default BrandProvider;
