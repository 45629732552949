/* eslint-disable no-empty */
/* eslint-disable consistent-return */
/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */

// React
import { useEffect, useState } from 'react';

// Components
import LoadingOverlay from '../../../shared/components/organisms/LoadingOverlay';

// Configs
import appRoutes from '../../../router/configs/routes.configs';
import i18n, { configureI18n } from '../../configs/i18n.configs';
import languages from '../../configs/languages.configs';

// Services
import IpServices from '../../../shared/services/ip.service';

// Hooks
import useRouterNavigation from '../../../hooks/useRouterNavigation';

// Others
import useStore from '../../../shared/store';

/* -------------------------------------------------------------------------- */
/*                                  Provider                                  */
/* -------------------------------------------------------------------------- */

const I18nProvider = (props) => {
  /* ******************************** LOGIC ******************************* */

  const [isLoading, setIsLoading] = useState(true);

  const { children } = props;

  const { brand, loadUser } = useStore();

  const { navigateTo } = useRouterNavigation();

  useEffect(() => {
    if (!brand) {
      return navigateTo(appRoutes.globalNotFound.path);
    }

    const detectLanguage = async () => {
      try {
        const response = await IpServices.get();

        loadUser(response);

        const { countryCode } = response;

        if (countryCode in languages && languages[countryCode] !== i18n.language) {
          // Configure i18n with brand-specific resources for the detected language

          configureI18n(brand.key, languages[countryCode]);
          return;
        }

        // Configure i18n with brand-specific resources for the default language
        configureI18n(brand.key);
      } catch (error) {
      } finally {
        setIsLoading(false);
      }
    };

    detectLanguage();
  }, []);

  /* ******************************** RENDERING ******************************* */

  if (isLoading) {
    return <LoadingOverlay visible />;
  }

  return children;
};

export default I18nProvider;
