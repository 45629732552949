const brands = {
  noliju: {
    key: 'noliju',
    path: 'noliju',
    accessKey: process.env.REACT_APP_NOLIJU_ACCESS_KEY,
    name: 'Noliju',
    website: {
      url: 'https://www.noliju.com',
      domain: 'Noliju.com',
    },
    features: {
      withUidCheck: true,
      withReferenceInput: false,
      withTextRecognition: false,
      withIssueRequest: true,
    },
    images: {
      logo: 'https://res.cloudinary.com/dnkj85yan/image/upload/v1686221866/certificate-request-page/brands/noliju/logo_xeq0kl.png',
      homePage: null,
      homePageCompressed: null,
      certificateExample:
        'https://res.cloudinary.com/dnkj85yan/image/upload/v1686222164/certificate-request-page/brands/noliju/certificate-example_eogrqk.png',
      certificateExampleCompressed:
        'https://res.cloudinary.com/dnkj85yan/image/upload/v1686222352/certificate-request-page/brands/noliju/certificate-example-compressed_xo69kg.png',
      favicon:
        'https://res.cloudinary.com/dnkj85yan/image/upload/v1686232858/certificate-request-page/brands/noliju/favicon_o3zfua.png',
      uidExample:
        'https://res.cloudinary.com/dnkj85yan/image/upload/v1686559793/certificate-request-page/brands/giambattista%20Valli/uid-example_mavfkh.jpg',
    },
    socialLinks: {
      facebook: 'https://www.facebook.com/noliju.fr',
      twitter: 'https://twitter.com/Noliju_fr',
      instagram: 'https://www.instagram.com/noliju',
      linkedin: 'https://www.linkedin.com/company/noliju',
    },
  },

  GiambattistaValli: {
    key: 'giambattistaValli',
    path: 'giambattista-valli',
    accessKey: process.env.REACT_APP_GIAMBATTISTA_VALLI_ACCESS_KEY,
    name: 'Giambattista Valli',
    website: {
      url: 'https://www.giambattistavalli.com',
      domain: 'GiambattistaValli.com',
    },
    features: {
      withUidCheck: true,
    },
    images: {
      logo: 'https://res.cloudinary.com/dnkj85yan/image/upload/v1686233198/certificate-request-page/brands/giambattista%20Valli/logo_kzwamx.png',
      homePage:
        'https://res.cloudinary.com/dnkj85yan/image/upload/v1686233227/certificate-request-page/brands/giambattista%20Valli/home-page_i7fjfi.png',
      homePageCompressed:
        'https://res.cloudinary.com/dnkj85yan/image/upload/v1686233192/certificate-request-page/brands/giambattista%20Valli/home-page-compressed_kssrvp.jpg',
      certificateExample:
        'https://res.cloudinary.com/dnkj85yan/image/upload/v1686233207/certificate-request-page/brands/giambattista%20Valli/certificate-example_xjkajl.png',
      certificateExampleCompressed:
        'https://res.cloudinary.com/dnkj85yan/image/upload/v1686233194/certificate-request-page/brands/giambattista%20Valli/certificate-example-compressed_b5cvhh.png',
      favicon:
        'https://res.cloudinary.com/dnkj85yan/image/upload/v1686233188/certificate-request-page/brands/giambattista%20Valli/favicon_xbn97f.png',
      uidExample:
        'https://res.cloudinary.com/dnkj85yan/image/upload/v1686559793/certificate-request-page/brands/giambattista%20Valli/uid-example_mavfkh.jpg',
    },
    socialLinks: {
      facebook: 'https://www.facebook.com/giambattistavalliparis',
      twitter: 'https://twitter.com/gbvparis',
      instagram: 'https://www.instagram.com/giambattistavalliparis',
      linkedin: 'https://www.linkedin.com/company/giambattista-valli',
    },
  },

  alexanderMcQUEEN: {
    key: 'alexanderMcQUEEN',
    path: 'alexander-mcqueen',
    accessKey: process.env.REACT_APP_ALEXANDER_MCQUEEN_ACCESS_KEY,
    name: 'Alexander McQUEEN',
    website: {
      url: 'https://www.alexandermcqueen.com',
      domain: 'AlexanderMcQUEEN.com',
    },
    features: {
      withUidCheck: false,
    },
    images: {
      logo: 'https://res.cloudinary.com/dnkj85yan/image/upload/v1686234078/certificate-request-page/brands/alexander%20mcqueen/logo_msamgo.png',
      homePage: null,
      homePageCompressed: null,
      certificateExample:
        'https://res.cloudinary.com/dnkj85yan/image/upload/v1686234081/certificate-request-page/brands/alexander%20mcqueen/certificate-example_fws4km.png',
      certificateExampleCompressed:
        'https://res.cloudinary.com/dnkj85yan/image/upload/v1686234077/certificate-request-page/brands/alexander%20mcqueen/certificate-example-compressed_ktsiiw.png',
      favicon:
        'https://res.cloudinary.com/dnkj85yan/image/upload/v1686234076/certificate-request-page/brands/alexander%20mcqueen/favicon_ncnvfx.ico',
      uidExample: null,
    },
    socialLinks: {
      facebook: 'https://www.facebook.com/AlexanderMcQueen',
      twitter: 'https://twitter.com/McQueen',
      instagram: 'https://www.instagram.com/alexandermcqueen',
      linkedin: 'https://www.linkedin.com/company/alexander-mcqueen',
    },
  },

  balenciaga: {
    key: 'balenciaga',
    path: 'balenciaga',
    accessKey: process.env.REACT_APP_BALENCIAGA_ACCESS_KEY,
    name: 'Balenciaga',
    website: {
      url: 'https://www.balenciaga.com',
      domain: 'Balenciaga.com',
    },
    features: {
      withUidCheck: false,
    },
    images: {
      logo: 'https://res.cloudinary.com/dnkj85yan/image/upload/v1686234366/certificate-request-page/brands/balenciaga/logo_ep8gvi.png',
      homePage: null,
      homePageCompressed: null,
      certificateExample:
        'https://res.cloudinary.com/dnkj85yan/image/upload/v1686234369/certificate-request-page/brands/balenciaga/certificate-example_kqixkz.png',
      certificateExampleCompressed:
        'https://res.cloudinary.com/dnkj85yan/image/upload/v1686234366/certificate-request-page/brands/balenciaga/certificate-example-example_zgsdax.png',
      favicon:
        'https://res.cloudinary.com/dnkj85yan/image/upload/v1686234365/certificate-request-page/brands/balenciaga/favicon_bjmn03.ico',
      uidExample: null,
    },
    socialLinks: {
      facebook: 'https://www.facebook.com/Balenciaga',
      instagram: 'https://www.instagram.com/balenciaga',
      linkedin: 'https://www.linkedin.com/company/balenciaga',
    },
  },

  bottegaVeneta: {
    key: 'bottegaVeneta',
    path: 'bottega-veneta',
    name: 'Bottega Veneta',
    accessKey: process.env.REACT_APP_BOTTEGA_VENETA_ACCESS_KEY,
    website: {
      url: 'https://www.bottegaveneta.com',
      domain: 'BottegaVeneta.com',
    },
    features: {
      withUidCheck: false,
    },
    images: {
      logo: 'https://res.cloudinary.com/dnkj85yan/image/upload/v1686234666/certificate-request-page/brands/bottega%20veneta/logo_zobdta.png',
      homePage: null,
      homePageCompressed: null,
      certificateExample:
        'https://res.cloudinary.com/dnkj85yan/image/upload/v1686234671/certificate-request-page/brands/bottega%20veneta/certificate-example_gcsu1r.png',
      certificateExampleCompressed:
        'https://res.cloudinary.com/dnkj85yan/image/upload/v1686234668/certificate-request-page/brands/bottega%20veneta/certificate-example-compressed_wpz1vy.png',
      favicon:
        'https://res.cloudinary.com/dnkj85yan/image/upload/v1686235007/certificate-request-page/brands/bottega%20veneta/favicon_ci2qlq.ico',
      uidExample: null,
    },
    socialLinks: {
      facebook: 'https://www.facebook.com/bottegaveneta',
      instagram: 'https://www.instagram.com/bottegavenetaworld',
      linkedin: 'https://www.linkedin.com/company/bottega-veneta',
    },
  },

  chanel: {
    key: 'chanel',
    path: 'chanel',
    name: 'Chanel',
    accessKey: process.env.REACT_APP_CHANEL_ACCESS_KEY,
    website: {
      url: 'https://www.chanel.com',
      domain: 'Chanel.com',
    },
    features: {
      withUidCheck: false,
    },
    images: {
      logo: 'https://res.cloudinary.com/dnkj85yan/image/upload/v1687254035/certificate-request-page/brands/chanel/logo_nimui2.svg',
      homePage: null,
      homePageCompressed: null,
      certificateExample:
        'https://res.cloudinary.com/dnkj85yan/image/upload/v1686235818/certificate-request-page/brands/chanel/certificate-example_crrwtm.png',
      certificateExampleCompressed:
        'https://res.cloudinary.com/dnkj85yan/image/upload/v1686235811/certificate-request-page/brands/chanel/certificate-example-compressed_tdlasl.png',
      favicon:
        'https://res.cloudinary.com/dnkj85yan/image/upload/v1686235809/certificate-request-page/brands/chanel/favicon_fapbxx.ico',
      uidExample: null,
    },
    socialLinks: {
      facebook: 'https://www.facebook.com/chanel',
      twitter: 'https://twitter.com/CHANEL',
      instagram: 'https://www.instagram.com/chanelofficial',
      linkedin: 'https://www.linkedin.com/company/chanel',
    },
  },

  gucci: {
    key: 'gucci',
    path: 'gucci',
    name: 'Gucci',
    accessKey: process.env.REACT_APP_GUCCI_ACCESS_KEY,
    website: {
      url: 'https://www.gucci.com',
      domain: 'Gucci.com',
    },
    features: {
      withUidCheck: false,
    },
    images: {
      logo: 'https://res.cloudinary.com/dnkj85yan/image/upload/v1686236317/certificate-request-page/brands/gucci/logo_kyvhuh.png',
      homePage: null,
      homePageCompressed: null,
      certificateExample:
        'https://res.cloudinary.com/dnkj85yan/image/upload/v1686236319/certificate-request-page/brands/gucci/certificate-example_g9sbrg.png',
      certificateExampleCompressed:
        'https://res.cloudinary.com/dnkj85yan/image/upload/v1686236433/certificate-request-page/brands/gucci/certificate-example-compressed_wdpx27.png',
      favicon:
        'https://res.cloudinary.com/dnkj85yan/image/upload/v1686236313/certificate-request-page/brands/gucci/favicon_xdk6a7.png',
      uidExample: null,
    },
    socialLinks: {
      facebook: 'https://www.facebook.com/GUCCI',
      twitter: 'https://twitter.com/gucci',
      instagram: 'https://www.instagram.com/gucci',
      linkedin: 'https://www.linkedin.com/company/gucci',
    },
  },

  halchimy: {
    key: 'halchimy',
    path: 'halchimy',
    name: 'Halchimy',
    accessKey: process.env.REACT_APP_HALCHIMY_ACCESS_KEY,
    website: {
      url: 'https://halchimy.com',
      domain: 'Halchimy.com',
    },
    features: {
      withUidCheck: false,
    },
    images: {
      logo: 'https://res.cloudinary.com/dnkj85yan/image/upload/v1686237020/certificate-request-page/brands/halchimy/logo_gibqgq.png',
      homePage: null,
      homePageCompressed: null,
      certificateExample:
        'https://res.cloudinary.com/dnkj85yan/image/upload/v1686237023/certificate-request-page/brands/halchimy/certificate-example_gcou2y.png',
      certificateExampleCompressed:
        'https://res.cloudinary.com/dnkj85yan/image/upload/v1686237018/certificate-request-page/brands/halchimy/certificate-example-compressed_z1yhpz.png',
      favicon:
        'https://res.cloudinary.com/dnkj85yan/image/upload/v1686237015/certificate-request-page/brands/halchimy/favicon_koffrz.png',
      uidExample: null,
    },
    socialLinks: {
      facebook: 'https://www.facebook.com/HalchimyOfficiel',
      twitter: 'https://twitter.com/halchimy',
      instagram: 'https://www.instagram.com/halchimy',
      linkedin: 'https://www.linkedin.com/company/halchimy',
    },
  },

  monnaiedeParis: {
    key: 'monnaiedeParis',
    path: 'monnaie-de-paris',
    name: 'Monnaie de Paris',
    accessKey: process.env.REACT_APP_MONNAIE_DE_PARIS_ACCESS_KEY,
    website: {
      url: 'https://www.monnaiedeparis.fr',
      domain: 'MonnaieDeParis.fr',
    },
    features: {
      withUidCheck: false,
      withIssueRequest: true,
    },
    images: {
      logo: 'https://res.cloudinary.com/dnkj85yan/image/upload/v1686237714/certificate-request-page/brands/monnaie%20de%20paris/logo_waq9rz.png',
      homePage: null,
      homePageCompressed: null,
      certificateExample:
        'https://res.cloudinary.com/dnkj85yan/image/upload/v1686237690/certificate-request-page/brands/monnaie%20de%20paris/certificate-example_sxuquc.png',
      certificateExampleCompressed:
        'https://res.cloudinary.com/dnkj85yan/image/upload/v1686237685/certificate-request-page/brands/monnaie%20de%20paris/certificate-example-compressed_oykg1t.png',
      favicon:
        'https://res.cloudinary.com/dnkj85yan/image/upload/v1686237713/certificate-request-page/brands/monnaie%20de%20paris/favicon_gaojb4.png',
      uidExample: null,
    },
    socialLinks: {
      facebook: 'https://www.facebook.com/pages/Monnaie-de-Paris/107522965943854',
      twitter: 'https://twitter.com/MonnaieDeParis',
      instagram: 'https://www.instagram.com/monnaiedeparis',
      linkedin: 'https://www.linkedin.com/company/monnaie-de-paris',
    },
  },

  saintLaurent: {
    key: 'saintLaurent',
    path: 'saint-laurent',
    name: 'Saint Laurent',
    accessKey: process.env.REACT_APP_SAINT_LAURENT_ACCESS_KEY,
    website: {
      url: 'https://www.ysl.com',
      domain: 'SaintLaurent.com',
    },
    features: {
      withUidCheck: false,
    },
    images: {
      logo: 'https://res.cloudinary.com/dnkj85yan/image/upload/v1686238047/certificate-request-page/brands/saint%20laurent/logo_w6e5hi.png',
      homePage: null,
      homePageCompressed: null,
      certificateExample:
        'https://res.cloudinary.com/dnkj85yan/image/upload/v1686238053/certificate-request-page/brands/saint%20laurent/certificate-example_pqk7nj.png',
      certificateExampleCompressed:
        'https://res.cloudinary.com/dnkj85yan/image/upload/v1686237685/certificate-request-page/brands/monnaie%20de%20paris/certificate-example-compressed_oykg1t.png',
      favicon:
        'https://res.cloudinary.com/dnkj85yan/image/upload/v1686238046/certificate-request-page/brands/saint%20laurent/favicon_j8q6ra.ico',
      uidExample: null,
    },
    socialLinks: {
      facebook: 'https://www.facebook.com/ysl',
      twitter: 'https://twitter.com/YSL',
      instagram: 'https://www.instagram.com/ysl',
      linkedin: 'https://www.linkedin.com/company/yves-saint-laurent',
    },
  },

  nomadsSurfing: {
    key: 'nomadsSurfing',
    path: 'nomads-surfing',
    name: 'Nomads Surfing',
    accessKey: process.env.REACT_APP_NOMADS_SURFING_ACCESS_KEY,
    website: {
      url: 'https://www.nomads-surfing.com',
      domain: 'NomadsSurfing.com',
    },
    features: {
      withUidCheck: false,
    },
    images: {
      logo: 'https://res.cloudinary.com/dnkj85yan/image/upload/v1690537445/certificate-request-page/brands/nomads%20Surfing/logo.png',
      homePage: null,
      homePageCompressed: null,
      certificateExample:
        'https://res.cloudinary.com/dnkj85yan/image/upload/v1690539165/certificate-request-page/brands/nomads%20Surfing/certificate.png',
      certificateExampleCompressed:
        'https://res.cloudinary.com/dnkj85yan/image/upload/v1690539772/certificate-request-page/brands/nomads%20Surfing/compressed-certificate.png',
      favicon:
        'https://res.cloudinary.com/dnkj85yan/image/upload/v1690537241/certificate-request-page/brands/nomads%20Surfing/favicon.png',
      uidExample: null,
    },
    socialLinks: {
      facebook: 'https://www.facebook.com/nomads.surfing',
      twitter: 'https://twitter.com/NomadsSurfing',
      instagram: 'https://www.instagram.com/nomads_surfing',
      linkedin: 'https://www.linkedin.com/company/nomads-surfing',
    },
  },

  brandZ: {
    key: 'brandZ',
    path: 'brand-z',
    name: 'Brand Z',
    accessKey: process.env.REACT_APP_BRAND_Z_ACCESS_KEY,
    website: {
      url: 'https://www.trust-place.com',
      domain: 'BrandZ.com',
    },
    features: {
      withUidCheck: false,
      withCareAndMaterialsSection: true,
      withOriginsAndProvenanceSection: true,
    },
    images: {
      logo: 'https://res.cloudinary.com/dnkj85yan/image/upload/v1690540895/certificate-request-page/brands/brand%20z/logo.png',
      homePage: null,
      homePageCompressed: null,
      certificateExample:
        'https://res.cloudinary.com/dnkj85yan/image/upload/v1690540558/certificate-request-page/brands/brand%20z/certificate.png',
      certificateExampleCompressed:
        'https://res.cloudinary.com/dnkj85yan/image/upload/v1690540610/certificate-request-page/brands/brand%20z/compressed-certificate.png',
      favicon:
        'https://res.cloudinary.com/dnkj85yan/image/upload/v1690540652/certificate-request-page/brands/brand%20z/favicon.png',
      uidExample: null,
    },
    socialLinks: {
      twitter: 'https://x.com/NFT_ownership?s=20',
      instagram: 'https://www.instagram.com/trust.place',
      linkedin: 'https://www.linkedin.com/company/trustplace',
    },
  },

  stDupont: {
    key: 'stDupont',
    path: 'st-dupont',
    name: 'S.T Dupont',
    accessKey: process.env.REACT_APP_ST_DUPONT_ACCESS_KEY,
    website: {
      url: 'https://fr.st-dupont.com',
      domain: 'StDupont.com',
    },
    features: {
      withUidCheck: false,
    },
    images: {
      logo: 'https://res.cloudinary.com/dnkj85yan/image/upload/v1690541758/certificate-request-page/brands/s.t%20dupont/logo.png',
      homePage: null,
      homePageCompressed: null,
      certificateExample:
        'https://res.cloudinary.com/dnkj85yan/image/upload/v1690542109/certificate-request-page/brands/s.t%20dupont/certificate.png',
      certificateExampleCompressed:
        'https://res.cloudinary.com/dnkj85yan/image/upload/v1690542235/certificate-request-page/brands/s.t%20dupont/compressed-certificate.png',
      favicon:
        'https://res.cloudinary.com/dnkj85yan/image/upload/v1690542028/certificate-request-page/brands/s.t%20dupont/favicon.ico',
      uidExample: null,
    },
    socialLinks: {
      facebook: 'https://www.facebook.com/STDupontOfficial',
      instagram: 'https://www.instagram.com/stdupont',
      linkedin: 'https://www.linkedin.com/company/st-dupont',
    },
  },

  ligneRoset: {
    key: 'ligneRoset',
    path: 'ligne-roset',
    name: 'LIGNE ROSET',
    accessKey: process.env.REACT_APP_LIGNE_ROSET_ACCESS_KEY,
    website: {
      url: 'https://www.ligne-roset.com',
      domain: 'LigneRoset.com',
    },
    features: {
      withUidCheck: false,
    },
    images: {
      logo: 'https://res.cloudinary.com/dnkj85yan/image/upload/v1690543365/certificate-request-page/brands/ligne%20roset/logo.png',
      homePage: null,
      homePageCompressed: null,
      certificateExample: '',
      certificateExampleCompressed: '',
      favicon:
        'https://res.cloudinary.com/dnkj85yan/image/upload/v1690543039/certificate-request-page/brands/ligne%20roset/favicon.png',
      uidExample: null,
    },
    socialLinks: {
      facebook: 'https://www.facebook.com/LigneRoset',
      twitter: 'https://twitter.com/ligneroset',
      instagram: 'https://www.instagram.com/ligneroset',
      linkedin: 'https://www.linkedin.com/company/ligne-roset',
    },
  },

  gatArt: {
    key: 'gatArt',
    path: 'gat-art',
    name: 'GAT-ART',
    accessKey: process.env.REACT_APP_GAT_ART_ACCESS_KEY,
    url: 'https://www.gat-art.com/',
    website: {
      url: 'https://www.gat-art.com',
      domain: 'GatArt.com',
    },
    features: {
      withUidCheck: false,
    },
    images: {
      logo: 'https://res.cloudinary.com/dnkj85yan/image/upload/v1690544078/certificate-request-page/brands/gat%20art/logo.png',
      homePage: null,
      homePageCompressed: null,
      certificateExample: '',
      certificateExampleCompressed: '',
      favicon:
        'https://res.cloudinary.com/dnkj85yan/image/upload/v1690544178/certificate-request-page/brands/gat%20art/favicon.png',
      uidExample: null,
    },
    socialLinks: {},
  },

  isabelMarant: {
    key: 'isabelMarant',
    path: 'isabel-marant',
    name: 'Isabel Marant',
    accessKey: process.env.REACT_APP_ISABEL_MARANT_ACCESS_KEY,
    website: {
      url: 'https://www.isabelmarant.com',
      domain: 'IsabelMarant.com',
    },
    features: {
      withUidCheck: true,
      hasShowCaseLink: true,
    },
    images: {
      logo: 'https://res.cloudinary.com/dnkj85yan/image/upload/v1690988417/certificate-request-page/brands/Isabel%20Marant/logo.png',
      homePage: null,
      homePageCompressed: null,
      certificateExample:
        'https://res.cloudinary.com/dnkj85yan/image/upload/v1690988524/certificate-request-page/brands/Isabel%20Marant/certificate.png',
      certificateExampleCompressed:
        'https://res.cloudinary.com/dnkj85yan/image/upload/v1690988621/certificate-request-page/brands/Isabel%20Marant/compressed-certificate.png',
      favicon:
        'https://res.cloudinary.com/dnkj85yan/image/upload/v1690988402/certificate-request-page/brands/Isabel%20Marant/favicon.ico',
      uidExample:
        'https://res.cloudinary.com/dnkj85yan/image/upload/v1690995520/certificate-request-page/brands/Isabel%20Marant/uid-check.png',
    },
    socialLinks: {
      facebook: 'https://www.facebook.com/isabelmarant',
      instagram: 'https://www.instagram.com/isabelmarant',
      linkedin: 'https://www.linkedin.com/company/isabel-marant',
    },
  },

  brandY: {
    key: 'brandY',
    path: 'brand-y',
    name: 'Brand Y',
    accessKey: process.env.REACT_APP_BRAND_Y_ACCESS_KEY,
    website: {
      url: 'https://www.trust-place.com',
      domain: 'BrandY.com',
    },
    features: {
      withUidCheck: false,
    },
    images: {
      logo: 'https://res.cloudinary.com/dnkj85yan/image/upload/v1690540895/certificate-request-page/brands/brand%20z/logo.png',
      homePage: null,
      homePageCompressed: null,
      certificateExample:
        'https://res.cloudinary.com/dnkj85yan/image/upload/v1690540558/certificate-request-page/brands/brand%20z/certificate.png',
      certificateExampleCompressed:
        'https://res.cloudinary.com/dnkj85yan/image/upload/v1690540610/certificate-request-page/brands/brand%20z/compressed-certificate.png',
      favicon:
        'https://res.cloudinary.com/dnkj85yan/image/upload/v1690540652/certificate-request-page/brands/brand%20z/favicon.png',
      uidExample: null,
    },
    socialLinks: {},
  },

  hermes: {
    key: 'hermes',
    path: 'hermes',
    name: 'Hermès',
    accessKey: process.env.REACT_APP_HERMES_ACCESS_KEY,
    website: {
      url: 'https://www.hermes.com',
      domain: 'Hermes.com',
    },
    features: {
      withUidCheck: false,
    },
    images: {
      logo: 'https://res.cloudinary.com/dnkj85yan/image/upload/v1692721315/certificate-request-page/brands/hermes/logo.png',
      homePage:
        'https://res.cloudinary.com/dnkj85yan/image/upload/v1692721595/certificate-request-page/brands/hermes/home-page.jpg',
      homePageCompressed: null,
      certificateExample:
        'https://res.cloudinary.com/dnkj85yan/image/upload/v1692721341/certificate-request-page/brands/hermes/certificate.png',
      certificateExampleCompressed:
        'https://res.cloudinary.com/dnkj85yan/image/upload/v1692722175/certificate-request-page/brands/hermes/compressed-certificate.png',
      favicon:
        'https://res.cloudinary.com/dnkj85yan/image/upload/v1692721705/certificate-request-page/brands/hermes/favicon.ico',
      uidExample: null,
    },
    socialLinks: {
      facebook: 'https://www.facebook.com/hermes',
      twitter: 'https://twitter.com/Hermes_Paris',
      instagram: 'https://www.instagram.com/hermes',
      linkedin: 'https://www.linkedin.com/company/hermes-group',
    },
  },

  dyades: {
    key: 'dyades',
    path: 'dyades',
    name: 'Dyades',
    accessKey: process.env.REACT_APP_DYADES_ACCESS_KEY,
    website: {
      url: 'https://www.trust-place.com',
      domain: 'Dyades.com',
    },
    features: {
      withUidCheck: false,
      withTextRecognition: false,
      withReferenceInput: true,
    },
    images: {
      logo: 'https://res.cloudinary.com/dnkj85yan/image/upload/v1692801922/certificate-request-page/brands/dyades/logo.png',
      homePage:
        'https://res.cloudinary.com/dnkj85yan/image/upload/v1692801945/certificate-request-page/brands/dyades/home-page.png',
      homePageCompressed: null,
      certificateExample: null,
      certificateExampleCompressed: null,
      favicon:
        'https://res.cloudinary.com/dnkj85yan/image/upload/v1690540652/certificate-request-page/brands/brand%20z/favicon.png',
      uidExample: null,
    },
    socialLinks: {
      facebook: 'https://www.facebook.com/dyadesparis',
      twitter: 'https://twitter.com/dyadesparis',
      instagram: 'https://www.instagram.com/dyadesparis',
      linkedin: 'https://www.linkedin.com/company/dyadesparis/',
    },
  },

  byredo: {
    key: 'byredo',
    path: 'byredo',
    name: 'Byredo',
    accessKey: process.env.REACT_APP_BYREDO_ACCESS_KEY,
    website: {
      url: 'https://www.byredo.com',
      domain: 'Byredo.com',
    },
    features: {
      withUidCheck: false,
      withTextRecognition: false,
      withReferenceInput: false,
    },
    images: {
      logo: 'https://res.cloudinary.com/dnkj85yan/image/upload/v1717585587/Byredo/logo_Byredo_ywrkkd.svg',
      homePage: null,
      homePageCompressed: null,
      certificateExample: null,
      certificateExampleCompressed: null,
      favicon: '',
      uidExample: null,
    },
    socialLinks: {
      facebook: 'https://www.facebook.com/byredo/',
      instagram: 'https://www.instagram.com/officialbyredo/',
    },
  },

  jacquemus: {
    key: 'jacquemus',
    path: 'jacquemus',
    name: 'Jacquemus',
    accessKey: process.env.REACT_APP_JACQUEMUS_ACCESS_KEY,
    website: {
      url: 'https://www.jacquemus.com/',
      domain: 'Jacquemus.com',
    },
    features: {
      withUidCheck: false,
      withTextRecognition: false,
      withReferenceInput: false,
    },
    images: {
      logo: 'https://res.cloudinary.com/dnkj85yan/image/upload/v1719485093/Photos/Jacquemus/idHdmUIL-k_f9eubb.png',
      homePage: null,
      homePageCompressed: null,
      certificateExample: null,
      certificateExampleCompressed: null,
      favicon: '',
      uidExample: null,
    },
    socialLinks: {
      facebook: 'https://www.facebook.com/JACQUEMUS',
      instagram: 'https://www.instagram.com/jacquemus/',
      linkedin: 'https://www.linkedin.com/company/jacquemus/',
    },
  },
};

const allowedPaths = Object.values(brands).map((brand) => brand.path);

export { brands, allowedPaths };
