/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */

import { useEffect } from 'react';
import useStore from '../../store';

/* -------------------------------------------------------------------------- */
/*                                  Component                                 */
/* -------------------------------------------------------------------------- */

const DynamicAppMetadata = (props) => {
  /* ******************************** HOOKS ******************************* */

  const { children } = props;

  const { brand } = useStore();

  useEffect(() => {
    const setLink = (rel, href) => {
      const faviconElement = document.querySelector(`link[rel="${rel}"]`);
      faviconElement.href = href;
    };

    if (brand) {
      const { favicon } = brand.images;
      setLink('icon', favicon);
      setLink('apple-touch-icon', favicon);
    }
  }, []);

  /* ******************************** RENDERING ******************************* */
  return children;
};

export default DynamicAppMetadata;
